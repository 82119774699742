import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: "AIzaSyA3HMz1rdZwJaLt3SwaKxOx9eK43iIFCZg",
  authDomain: "giron-web.firebaseapp.com",
  projectId: "giron-web",
  storageBucket: "giron-web.firebasestorage.app",
  messagingSenderId: "298733999284",
  appId: "1:298733999284:web:e566c0abe7c7e8f08bf3e1",
  measurementId: "G-GZ1CRED59P"
};


// Initialize Firebase

const firebaseApp = initializeApp(firebaseConfig);
const firebaseStorage = getStorage(firebaseApp);

export { firebaseApp, firebaseConfig, firebaseStorage };
